<template lang="html" src="./replacePartFile.template.vue"></template>

<style lang="scss" src="./replacePartFile.scss"></style>

<script>
import { ApiErrorParser, UploadManager, ProgressSingle, FileConverter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./replacePartFile.i18n');

export default {
  name: 'ReplacePartFile',
  components: {
    UploadManager,
    ProgressSingle
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      canClose: false,
      compressionInProgress: false,
      dialogController: false,
      uploadStatus: 0,
      partFile: '',
      partsEditData: [],
      uploadErrorMessage: null,
    };
  },
  methods: {
    /**
     * SELECTOR FOR UPLOAD NEW PART
     */
    openFileSelector() {
      this.resetData();
      const fileSelector = this.$refs.fileInput;
      fileSelector.value = '';
      fileSelector.click();
    },
    cancelUploadFile() {
      this.dialogController = false;
      setTimeout(this.resetData, 500);
    },
    resetData() {
      this.uploadErrorMessage = null;
      this.canClose = false;
      this.uploadStatus = 0;
      this.partFile = '';
      this.partsEditData = [];
    },
    preparePartsEditor() {
      const fileName = this.partFile[0].name.replace(/\.stl|\.3mf|\.stp|\.step|\.obj|\.iges|\.igs/i, '');
      this.partsEditData.push({
        fileID: 0,
        name: fileName,
        description: '',
        internalReference: '',
      });
      this.uploadStatus = 0;
      this.uploadErrorMessage = null;
      this.dialogPartsFileUploadStatus = true;
      this.dialogController = true;
      this.uploadPartFile();
    },
    /**
     * PREPARE UPLOAD FILE AND CHECK HIS EXTENSION
     */
    partsUploadFilesChanges() {
      const fileSelector = this.$refs.fileInput;
      if (fileSelector.value && fileSelector.files.length > 0) {
        this.partFile = fileSelector.files;
        const files = this.partFile[0].name;
        const regex = /(.stl|.3mf|.stp|.step|.obj|.iges|.igs)$/i;
        if (regex.test(files)) {
          this.preparePartsEditor();
        } else {
          this.$notification.notify('DANGER',this.$t('wrongExt'));
        }
      }
    },
    /**
     * UPLOAD NEW PART
     */
    uploadPartFile() {
      const fileToUpload = this.partFile[this.partsEditData[0].fileID];

      const fileExt = fileToUpload.name.split('.').pop().toLowerCase();

      if(fileExt === 'stl') {
        const self = this;
        this.compressionInProgress = true;
        /**
         * Convert to BLS
         */
        let buffer = null;
        let fileName = fileToUpload.name;
        fileName = fileName.substring(0, fileName.length - 4);

        const reader = new FileReader();
        reader.onloadend = function() {
          buffer = reader.result;

          FileConverter.convertFile(buffer, 'stl', 'bls').then(newData => {
            self.compressionInProgress = false;
            const newFileToUpload = new File([newData], fileName + '.bls');

            self.$refs.UploadManagerRef.uploadPart(
              newFileToUpload,
              self.part.brand_id,
              self.part._id
            );

          });
        };

        reader.readAsArrayBuffer(fileToUpload);

      } else {
        this.$refs.UploadManagerRef.uploadPart(
          fileToUpload,
          this.part.brand_id,
          this.part._id
        );
      }
    },
    uploadSucceeded() {
      this.$emit('PartFileUploaded');
      this.uploadStatus = 100;
      this.canClose = true;
    },
    checkProgress(status) {
      // We stop at 99% because we will wait for the request response
      this.uploadStatus = Math.round(99 * (status / 100));
    },
    listUploadError(error) {
      let errorMessage = this.$t('UploadError');
      if(error.response) {
        if(JSON.parse(error.response)?.message === 'Part file is too large') {
          errorMessage = this.$t('PartTooLarge');
        }
      }
      this.uploadErrorMessage = errorMessage;
      this.canClose = true;
    },
  },
};
</script>
