<template>
  <v-tooltip right>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-btn
          text
          fab
          @click="$emit('goToBrandPartOrders')"
        >
          <v-badge
            bordered
            color="orange"
            class="badgeBrandPartOrders"
            overlap
            :value="currentOrderAwaitingPayment.length > 0"
          >
            <template v-slot:badge>
              <span>
                {{ currentOrderAwaitingPayment.length }}
              </span>
            </template>
            <v-icon :color="color" small>
              fas fa-box-open
            </v-icon>
          </v-badge>
        </v-btn>
      </div>
    </template>
    <span> {{ $t('Orders') }} </span>
    <span>{{ $t('(ordersInProgress)', {numberOfOrders: currentOrderNotDelivered.length}) }}</span>
  </v-tooltip>
</template>

<script>
const i18nData = require('./brandPartOrders.i18n');

export default {
  name: 'BrandPartOrders',
  props: {
    part: {
      type: Object,
      required: true
    },
    brandPartOrders: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: () => {
        return 'primary';
      } 
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
    };
  },
  watch: {

  },
  computed: {
    currentOrderAwaitingPayment() {
      return this.brandPartOrders.filter(o => {
        return o.status === 'NEW' && o.paymentStatus === 'WAITING';
      });
    },
    currentOrderNotDelivered() {
      return this.brandPartOrders.filter(o => {
        return o.status !== 'DELIVERED' && o.status !== 'CANCELED' && o.quote.dispatch.some(d => {
          return d.items.some(i => {
            return i.part === this.part._id && !i.canceled;
          });
        });
      });
    }
  },
  created() {
  },
  methods: {

  }
};
</script>
 
 <style>
  .badgeBrandPartOrders .v-badge__wrapper .v-badge__badge {
    inset: auto auto calc(100% - 6px) calc(100% - 13px) !important;
}
 </style>