<template>
  <div class="stickyContainer">
    <v-card class="menuAction rounded-pill pa-0 d-flex flex-column">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              text
              fab
              @click="$emit('open','ModalPartInformation')"
            >
              <v-icon color="primary" small>
                fas fa-file-alt
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span> {{ $t('partInformation') }}</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              text
              fab
              @click="$emit('open','attachmentsManager')"
            >
              <v-icon color="primary" small>
                fas fa-paperclip fa-flip-vertical
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span> {{ $t('partInformation') }}</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              text
              fab
              :disabled="!$appConfig.brand.features.enableTags || part.brand_id !== $route.params.brandUUID"
              @click="$emit('open','ModalPartTags')"
            >
              <v-icon color="primary" small>
                fas fa-tags
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span v-if="!$appConfig.brand.features.enableTags">
          {{ $t('TagsFeatureIsAvailableAsPremiumOption') }}
        </span>
        <span v-else-if="part.brand_id !== $route.params.brandUUID">
          {{ $t('noAccess') }}
        </span>
        <span v-else>{{ $t('tags') }}</span>
      </v-tooltip>
      <BrandPartQuotes
        :brand-part-quotes="brandPartQuotes"
        :color="partQuotesIconColor"
        @goToBrandPartQuotes="goToBrandPartQuotes"
      ></BrandPartQuotes> 
      <BrandPartOrders
        :part="part"
        :brand-part-orders="brandPartOrders"
        @goToBrandPartOrders="goToBrandPartOrders"
      ></BrandPartOrders>
      <v-btn
        text
        fab
        color="info"
        :loading="loadingFile"
        :disabled="part.brand_id !== $route.params.brandUUID"
        @click="$emit('open','fileExtensionModal')"
      >
        <v-icon small>
          fas fa-fas fa-download
        </v-icon>
      </v-btn>
      <PartSharingManager
        :part="part"
        :share-with="shareWith"
        :brand="$appConfig.brand"
        :current-sharing="currentSharing"
        :disabled="part.brand_id !== $route.params.brandUUID"
        @add="addSharing"
        @delete="deleteSharing"
        ref="sharePart"
      >
        <template v-slot:append-add>
          <SupportEmail
            :hidden-pre-subject="`[SHARING-${part._id}]`"
            :default-subject="$t('SharingSupportSubject')"
            :display-information="false"
            :display-button="false"
            ref="supportSharing"
          ></SupportEmail>
          <v-btn
            color="info"
            @click="$refs.supportSharing.open()"
            class="text-none mt-2 ml-3"
            fab
            small
            title="Support"
          >
            <v-icon small>
              fas fa-headset
            </v-icon>
          </v-btn>
        </template>
      </PartSharingManager>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              :disabled="!$appConfig.brand.features.enableSerialNumber"
              text
              fab
              color="info"
              :to="{name: 'Traceability', query: {partName: part.name}}"
            >
              <v-img
                v-if="$appConfig.brand.features.enableSerialNumber"
                src="../../assets/scan_color_info.png"
                max-height="18px"
                contain
              >
              </v-img>
              <v-img
                v-else
                src="../../assets/scan_color_disabled.png"
                max-height="18px"
                contain
              >
              </v-img>
            </v-btn>
          </div>
        </template>
        <span v-if="$appConfig.brand.features.enableSerialNumber">
          {{ $t('SerialNumbersGenerated') }}
        </span>
        <span v-else>
          {{ $t('SerialNumberFeatureDisabled') }}
        </span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              text
              fab
              @click="$emit('open','SupportModal')"
            >
              <v-icon color="primary" small>
                fas fa-headset
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span>
          {{ $t('ContactSupport') }}</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              :disabled="!canBeDeleted || part.brand_id !== $route.params.brandUUID"
              text
              fab
              @click="$emit('open','deletePartModal')"
            >
              <v-icon color="info" small>
                fas fa-trash
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span v-if="!canBeDeleted">{{ $t('cantDeletePart') }}</span>
        <span v-else>{{ $t('DeletePart') }} </span>
      </v-tooltip>
    </v-card>
  </div>
</template>

<script>
import {
  PartSharingManager,
  ApiErrorParser,
} from '@cloudmanufacturingtechnologies/portal-components';

import SupportEmail from '../supportEmail/SupportEmail';
import BrandPartOrders from './components/brandPartOrders/BrandPartOrders';
import BrandPartQuotes from './components/brandPartQuotes/BrandPartQuotes';
const i18nData = require('./menuActions.i18n');

export default {
  name: 'MenuActions',
  props: {
    part: {
      type: Object,
      default: null,
    },
    brandPartOrders: {
      type: Array,
      required: true,
    },
    brandPartQuotes: {
      type: Array,
      required: true,
    },
    loadingFile: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    SupportEmail,
    PartSharingManager,
    BrandPartOrders,
    BrandPartQuotes,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  computed: {
    canBeDeleted() {
      return this.part && (
        this.part.status === 'NEW'
        || (this.part.status === 'HOMOLOGATION' 
          && (this.part.homologation?.status === 'READY' || this.part.homologation?.status === 'TESTING' || this.part.homologation?.status === 'REJECTED'))) && this.part.brand_id === this.$route.params.brandUUID;
    },
    partQuotesIconColor() {
      if(this.brandPartQuotes.some(q => {
        return q.status === 'LOCKED' || q.status === 'ADMIN_LOCKED' || q.status === 'READY';
      })) {
        return 'info';
      }
      return 'info';
    }
  },
  data() {
    return {
      fileData: null,
      fileDataDownloaded: {},
      /**
       * SharingManager
       */
      // brandFeatures: null,
      shareWith: null,
      currentSharing: [],
    };
  },
  created() {
    this.initSharingManager();
  },
  methods: {
    /**
     * SharingManager
     */
    initSharingManager() {
      if(this.$appConfig.brand.features.sharing.enabled) {
        //Get list of shareWith
        this.$apiInstance.getSharingWithBrands(this.$appConfig.currentBrand)
          .then((shareWithData) => {
            this.shareWith = shareWithData;
            //Get list of existing sharing for this part
            this.$apiInstance.getBrandPartSharing(this.$appConfig.currentBrand, this.$route.params.partUUID)
              .then((currentSharingData) => {
                this.currentSharing = currentSharingData;
              }, (error) => {
                this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
              });
          }, (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          });
      }
    },
    addSharing(addBrandPartSharingBody) {
      //Save a new share
      this.$apiInstance.addBrandPartSharing(this.$appConfig.currentBrand, this.$route.params.partUUID, addBrandPartSharingBody)
        .then(() => {
          this.$notification.notify('SUCCESS',this.$t('PartShared'));
          this.initSharingManager();
        }, (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        });
    },
    deleteSharing(sharingUUID) {
      //Delete sharing
      this.$apiInstance.deleteBrandPartSharing(this.$appConfig.currentBrand, this.$route.params.partUUID, sharingUUID)
        .then(() => {
          this.$notification.notify('SUCCESS',this.$t('SharingRemoved'));
          this.initSharingManager();
        }, (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        });
    },
    /**
     * End SharingManager
     */
    goToBrandPartOrders() {
      this.$router.push({name: 'PartOrders', params: { brandUUID: `${this.$appConfig.currentBrand}`, partUUID: `${this.part._id}`}});
    },
    goToBrandPartQuotes() {
      this.$router.push({name: 'PartQuotes', params: { brandUUID: `${this.$appConfig.currentBrand}`, partUUID: `${this.part._id}`}});
    },
  },
};
</script>

<style scoped lang="scss">
.stickyContainer {
  width: 56px;
  position: sticky;
  top: 10px;
}
.menuAction {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
</style>