var render = function render(){
  var _vm$part, _vm$part2, _vm$part2$dna, _vm$part3, _vm$part3$homologatio, _vm$part3$homologatio2, _vm$part4, _vm$part4$homologatio, _vm$part$homologation, _vm$part$homologation2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-Part"
  }, [_vm.part ? _c('FileExtensionModal', {
    ref: "fileExtensionModalRef",
    attrs: {
      "part": _vm.part,
      "brand": _vm.$appConfig.brand,
      "extensions": _vm.downloadChoices,
      "serial-number-extensions": _vm.downloadSerialNumberChoices
    },
    on: {
      "downloadPartFile": _vm.getPartFile,
      "downloadPartSerialNumberFile": _vm.getPartSerialNumberFile
    }
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "width": "80%"
    },
    model: {
      value: _vm.dialogPartAttachmentsManager,
      callback: function ($$v) {
        _vm.dialogPartAttachmentsManager = $$v;
      },
      expression: "dialogPartAttachmentsManager"
    }
  }, [_c('v-card', [_c('PartAttachmentsManager', {
    attrs: {
      "part": _vm.part
    },
    on: {
      "refresh": _vm.getBrandPart
    }
  })], 1)], 1), _vm.part ? _c('ViewerObjectsSplitter', {
    ref: "viewerObjectsSplitterRef",
    attrs: {
      "part": _vm.part,
      "part-file": _vm.partFileDataURL['stl'],
      "display-confirmation": _vm.displayObjectsConfirmation
    },
    on: {
      "acceptManufacturingWithMultipleObjects": _vm.acceptManufacturingWithMultipleObjects
    }
  }) : _vm._e(), _vm.part ? _c('div', {
    staticClass: "d-none"
  }, [_c('FormPart', {
    ref: "partInformation",
    attrs: {
      "part": _vm.part,
      "editable": _vm.editable
    },
    on: {
      "saveBrandPartInformation": _vm.saveBrandPartInformation
    }
  })], 1) : _vm._e(), _c('a', {
    ref: "downloadFileButton",
    staticClass: "d-none",
    attrs: {
      "download": _vm.partDownloadName
    }
  }), _c('a', {
    ref: "downloadSerialNumberFileButton",
    staticClass: "d-none",
    attrs: {
      "download": _vm.partSerialNumberDownloadName
    }
  }), _vm.part ? _c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('SupportEmail', {
    ref: "support",
    attrs: {
      "hidden-pre-subject": `[CMT Support] (Pièce ${_vm.part.name})`,
      "default-subject": '',
      "display-button": false
    }
  }), _c('v-col', {
    staticClass: "hoverPartInformation text-wrap display-1 partTitle d-flex",
    attrs: {
      "align-start": "",
      "align-self": "center"
    }
  }, [_vm.part.status === 'HOMOLOGATED' ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-img', _vm._g({
          staticClass: "mr-3 mt-1",
          attrs: {
            "src": require("../../../public/img/icons/validHomologation-primary.svg"),
            "alt": "homologated icon",
            "contain": "",
            "max-width": "35px",
            "max-height": "35px"
          }
        }, on))];
      }
    }], null, false, 4078286651)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('homologatedPart')))])]) : _vm._e(), _vm.part.brand_id !== _vm.$route.params.brandUUID ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-2 mr-1",
          attrs: {
            "color": "success"
          }
        }, on), [_vm._v(" fas fa-share-alt ")])];
      }
    }], null, false, 3571622220)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('sharedPart')))])]) : _vm._e(), _vm.part.dna && _vm.part.dna.typeOfProduction === 'prototype' ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-img', _vm._g({
          staticClass: "mr-3",
          attrs: {
            "src": require("../../../public/img/icons/modeling.svg"),
            "alt": "prototype icon",
            "contain": "",
            "max-width": "40px",
            "max-height": "40px"
          }
        }, on))];
      }
    }], null, false, 2601239329)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('prototypeProduction')))])]) : _vm._e(), _c('span', {
    staticClass: "text-decoration-none d-block text-truncate ml-2",
    staticStyle: {
      "max-width": "70vw"
    }
  }, [_vm._v(" " + _vm._s(_vm.part.name) + " "), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('div', _vm._g({
          staticClass: "d-inline-block"
        }, on), [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openModal('ModalPartInformation');
            }
          }
        }, [_vm.editable ? _c('v-icon', {
          staticClass: "iconModifyPartInformation",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-pen ")]) : _c('v-icon', {
          staticClass: "iconModifyPartInformation",
          attrs: {
            "color": "info",
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)], 1)];
      }
    }], null, false, 2584125066)
  }, [_vm.editable ? _c('span', [_vm._v(_vm._s(_vm.$t('Modify')))]) : _c('span', [_vm._v(_vm._s(_vm.$t('showInformation')))])])], 1)], 1), ((_vm$part = _vm.part) === null || _vm$part === void 0 ? void 0 : _vm$part.brand_id) === _vm.$route.params.brandUUID ? _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "align": "end",
      "cols": "auto"
    }
  }, [_c('RepeatabilityIndex', {
    attrs: {
      "part": _vm.part,
      "part-orders": _vm.brandPartOrders,
      "dense": true,
      "size": 'small'
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "pt-0 pb-2"
  }, [_c('v-divider')], 1)], 1), _vm.part ? _c('v-row', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-col', {
    staticClass: "pl-3 pr-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('MenuActions', {
    attrs: {
      "part": _vm.part,
      "brand-part-orders": _vm.brandPartOrders,
      "brand-part-quotes": _vm.brandPartQuotes,
      "loading-file": _vm.loadingFile
    },
    on: {
      "open": _vm.openModal
    }
  })], 1), _c('v-col', {
    staticClass: "no-scrollbar",
    class: {
      'no-scrollbar': _vm.viewerFullscreen
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-none"
  }, [_c('PartTags', {
    ref: "partTags",
    attrs: {
      "part": _vm.part,
      "brand-parts-tags": _vm.brandPartsTags
    },
    on: {
      "saveBrandPartTags": _vm.saveBrandPartTags
    }
  })], 1), _c('v-card', [_vm.part.tags && _vm.part.tags.length !== 0 ? _c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2 mb-1",
    attrs: {
      "color": "success",
      "small": ""
    }
  }, [_vm._v(" fas fa-tags ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('tags')) + " ")]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on;
        return [_c('div', _vm._g({
          staticClass: "d-inline-block"
        }, on), [_c('v-icon', {
          staticClass: "ml-2 mb-1",
          attrs: {
            "disabled": !_vm.$appConfig.brand.features.enableTags || _vm.part.brand_id !== _vm.$route.params.brandUUID,
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openModal('ModalPartTags');
            }
          }
        }, [_vm._v(" fas fa-pen ")])], 1)];
      }
    }], null, false, 2027102705)
  }, [!_vm.$appConfig.brand.features.enableTags || _vm.part.brand_id !== _vm.$route.params.brandUUID ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('noAccess')) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t('modifTags')))])])], 1) : _c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "error",
      "small": ""
    }
  }, [_vm._v(" fas fa-tag ")]), _c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('untagged')) + " ")]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "info",
            "disabled": !_vm.$appConfig.brand.features.enableTags || _vm.part.brand_id !== _vm.$route.params.brandUUID
          },
          on: {
            "click": function ($event) {
              return _vm.openModal('ModalPartTags');
            }
          }
        }, [_c('v-icon', [_vm._v(" fas fa-plus-circle ")])], 1)], 1)];
      }
    }], null, false, 3421483954)
  }, [!_vm.$appConfig.brand.features.enableTags || _vm.part.brand_id !== _vm.$route.params.brandUUID ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('noAccess')) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t('addTags')))])])], 1), _c('v-card-text', _vm._l(_vm.part.tags, function (tag, key) {
    return _c('v-chip', {
      key: key,
      staticClass: "ml-2 mb-1"
    }, [_c('span', [_vm._v(_vm._s(tag))])]);
  }), 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pt-0"
  }, [_vm.brandPartQuotes && _vm.brandPartOrders && _vm.part ? _c('HomologationStepperV2', {
    ref: "homologationStepper",
    attrs: {
      "part": _vm.part,
      "brand": _vm.$appConfig.brand,
      "part-quotes": _vm.brandPartQuotes,
      "part-orders": _vm.brandPartOrders,
      "available-technologies-and-materials": _vm.technologiesAndMaterials ? _vm.technologiesAndMaterials.filter(function (t) {
        return t.printersSizeCompatible;
      }) : null,
      "estimated-prices": _vm.estimatedPrices,
      "refined-estimated-prices": _vm.refinedEstimatedPrices,
      "private-suppliers-prices": _vm.privateSuppliersEstimatedPrices
    },
    on: {
      "openModal": _vm.openModal,
      "update": _vm.updateStatus,
      "goTo": _vm.goTo,
      "removeCriticalDimensions": _vm.removeCriticalDimensions,
      "removeSerialNumber": _vm.removeSerialNumber,
      "sendPartHomologationToPublicSupplier": function ($event) {
        return _vm.modifyBrandPartStatus('HOMOLOGATION');
      },
      "sendPartHomologationToPrivateSupplier": _vm.sendPartHomologationToPrivateSupplier,
      "addPartToBasket": _vm.addPartToBasket,
      "selectSupplierAndAddPartToBasket": _vm.selectSupplierAndAddPartToBasket,
      "updatePartQuotes": _vm.updatePartQuotes,
      "modifyPartStatus": _vm.modifyBrandPartStatus
    }
  }) : _vm._e(), _c('ReplacePartFile', {
    ref: "replacePartFile",
    attrs: {
      "part": _vm.part
    },
    on: {
      "PartFileUploaded": _vm.partFileUploaded
    }
  }), _c('ReplacePartFileWithModifications', {
    ref: "replacePartFileWithModifications",
    attrs: {
      "part": _vm.part,
      "viewer-part-file": _vm.viewer3dFile
    },
    on: {
      "PartFileUploaded": _vm.partFileUploaded
    }
  }), _c('v-dialog', {
    staticStyle: {
      "z-index": "1000"
    },
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dnaModal,
      callback: function ($$v) {
        _vm.dnaModal = $$v;
      },
      expression: "dnaModal"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "rgb(238, 238, 238)",
      "max-width": "100%"
    }
  }, [_c('v-card-title', {
    ref: "DNAForm"
  }, [_vm.part.dna && _vm.part.dna.completed ? _c('div', [_c('v-icon', {
    staticClass: "mb-1 mr-2",
    attrs: {
      "color": "success"
    }
  }, [_vm._v(" fas fa-check-circle ")]), _vm._v(" " + _vm._s(_vm.$t("ConstructedDNA")) + " ")], 1) : _c('div', {
    staticClass: "getAttentionHeartbeat"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-2",
    attrs: {
      "color": "orange"
    }
  }, [_vm._v(" fas fa-dna ")]), _vm._v(" " + _vm._s(_vm.$t("DNAToBuild")) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDnaModal
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _c('span', [_vm._v(_vm._s(_vm.$t('Close')))])], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500"
    },
    model: {
      value: _vm.warningCloseDnaForm,
      callback: function ($$v) {
        _vm.warningCloseDnaForm = $$v;
      },
      expression: "warningCloseDnaForm"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline primary--text mb-6"
  }, [_c('v-icon', [_vm._v(" fas fa-exclamation-triangle mr-4 primary--text ")]), _vm._v(" " + _vm._s(_vm.$t("warningCloseDnaFormTitle")) + " ")], 1), _c('v-card-text', [_c('p', [_vm._v(_vm._s(_vm.$t("warningCloseDnaFormText1")))]), _c('p', [_vm._v(_vm._s(_vm.$t("warningCloseDnaFormText2")))])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    on: {
      "click": function ($event) {
        _vm.warningCloseDnaForm = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Don'tClose")) + " ")]), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function ($event) {
        _vm.dnaModal = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CloseAndContinueLater")) + " ")])], 1)], 1)], 1)], 1), _c('v-card-text', [_vm.part && _vm.technologiesAndMaterials ? _c('DnaFormV3', {
    key: 'dnaForm' + _vm.keyComponent,
    ref: "dnaForm",
    attrs: {
      "dna-basis": _vm.part.dna ? _vm.part.dna : {},
      "part": _vm.part,
      "size": _vm.part.size ? _vm.part.size : {},
      "stats": {},
      "analysis-status": _vm.part.analysisStatus,
      "estimated-prices": _vm.estimatedPrices,
      "technologies-and-materials": _vm.technologiesAndMaterials,
      "editor": true
    },
    on: {
      "saved": _vm.saveDNA,
      "cancel": _vm.cancelDnaForm,
      "next": _vm.dnaModalNextStep,
      "dnaFormWantToContactSupport": function ($event) {
        _vm.$refs.support.dialogSupportEmail = true;
      },
      "openCriticalDimensionsTool": _vm.openCriticalDimensionsTool
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "col-sm-12 pt-0",
    class: _vm.viewerFullscreen ? 'col-12' : 'col-md-6'
  }, [_vm.part ? _c('v-card', [!_vm.part.file && !_vm.part.originalFile ? _c('v-card-text', [_c('v-alert', {
    staticClass: "text-center py-8",
    attrs: {
      "type": "error",
      "text": "",
      "prominent": ""
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('PartFileMissing')) + " ")]), _c('v-btn', {
    staticClass: "text-none white--text mx-auto",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('fileModal');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('UploadANew3dFile')) + " ")], 1)], 1)], 1) : _c('v-card-text', {
    staticClass: "text-center justify-center pt-5",
    staticStyle: {
      "height": "90%"
    }
  }, [_vm.part.originalFile && _vm.part.fileConversionStatus !== 'DONE' ? _c('div', {
    staticClass: "pa-12"
  }, [_vm.part.fileConversionStatus !== 'ERROR' ? _c('div', [_c('SpinnerBeelse', {
    attrs: {
      "multiple": "",
      "color": "orange",
      "logo": "simple",
      "size": 150,
      "display-logo": false
    }
  }), _c('p', {
    staticClass: "headline mt-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('STEPMeshingInProgress')) + " ")]), _c('p', {
    staticClass: "subtitle-1 mb-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('ThisCanTakeFewMinutes')) + " ")])], 1) : _c('div', [_c('v-alert', {
    staticClass: "headline mb-12",
    attrs: {
      "type": "error"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('STEPMeshingErrorTitle')) + " ")])]), _c('p', {
    staticClass: "mb-2 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('STEPMeshingErrorText1')) + " ")]), _c('p', {
    staticClass: "mb-8 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('FileErrorText1')) + " ")]), _c('p', {
    staticClass: "headline mb-12"
  }, [_c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.$refs.support.dialogSupportEmail = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-headset ")]), _vm._v(" " + _vm._s(_vm.$t('ContactSupport')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('fileModal');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('UploadANew3dFile')) + " ")], 1)], 1)], 1)]) : _vm.viewer3dFile && _vm.displayViewer && _vm.partModifications ? _c('div', [_c('ViewerCriticalDimension', {
    ref: "viewerCriticalDimension",
    attrs: {
      "part": _vm.part,
      "viewer3d-file": _vm.viewer3dFile
    },
    on: {
      "dialogClosed": _vm.dialogClosed,
      "save": _vm.modifyBrandPartCriticalDimensions
    }
  }), _c('ViewerSerialNumber', {
    ref: "viewerSerialNumber",
    attrs: {
      "part": _vm.part,
      "viewer3d-file": _vm.viewer3dFile
    },
    on: {
      "dialogClosed": _vm.dialogClosed,
      "save": _vm.modifyBrandPartSerialNumberSettings
    }
  }), _c('Viewer3d', {
    ref: "viewer3dRef",
    attrs: {
      "part-file": _vm.viewer3dFile,
      "information-control-enabled": true,
      "part": _vm.part,
      "toolbar": true,
      "clipping": true,
      "reorientation-tool": _vm.part.status === 'NEW' && _vm.part.analysisStatus !== 'IN_PROGRESS',
      "initial-fullscreen": _vm.viewerFullscreen,
      "can-modify-critical-dimensions": _vm.part.status === 'NEW',
      "critical-dimensions-enabled": true,
      "modifications": _vm.partModifications,
      "modifications-enabled": !!_vm.part.homologation,
      "can-modify-modifications": _vm.part.homologation && _vm.part.status === 'NEW',
      "serial-number-enabled": _vm.$appConfig.brand.features.enableSerialNumber,
      "can-modify-serial-number": false,
      "picture-tool-enabled": _vm.part.brand_id === _vm.$appConfig.brand._id
    },
    on: {
      "fullscreen": _vm.switchFullscreen,
      "modifyPartCriticalDimensions": _vm.modifyBrandPartCriticalDimensions,
      "modifySerialNumber": _vm.modifyBrandPartSerialNumberSettings,
      "saveImage": _vm.addBrandPartImage,
      "renderingReady": _vm.viewerRenderingReady
    }
  })], 1) : _c('div', {
    staticClass: "pa-12"
  }, [_vm.part.analysisStatus !== 'FAILED' ? _c('div', [_c('SpinnerBeelse', {
    attrs: {
      "multiple": "",
      "color": "orange",
      "logo": "simple",
      "size": 150,
      "display-logo": false
    }
  }), _vm.part.analysisStatus !== 'DONE' ? _c('p', {
    staticClass: "headline mb-12 mt-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('AnalysisInProgress')) + " ")]) : _vm._e()], 1) : _c('div', [_c('v-alert', {
    staticClass: "headline mb-12",
    attrs: {
      "type": "error"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('AnalysisFailed')) + " ")])]), _c('p', {
    staticClass: "mb-2 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('AnalysisFailedText1')) + " ")]), _c('p', {
    staticClass: "mb-8 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('FileErrorText1')) + " ")]), _c('p', {
    staticClass: "headline mb-12"
  }, [_c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.$refs.support.dialogSupportEmail = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-headset ")]), _vm._v(" " + _vm._s(_vm.$t('ContactSupport')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('fileModal');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('UploadANew3dFile')) + " ")], 1)], 1)], 1)])])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "pt-0 pl-3 pl-md-0"
  }, [_vm.part.status === 'NEW' ? [(_vm$part2 = _vm.part) !== null && _vm$part2 !== void 0 && (_vm$part2$dna = _vm$part2.dna) !== null && _vm$part2$dna !== void 0 && _vm$part2$dna.completed ? _c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("PricesFor")) + " " + _vm._s(_vm.$t(_vm.part.dna.technology)) + " - " + _vm._s(_vm.$t(_vm.part.dna.material)))]), _c('v-card-text', {
    staticClass: "mb-4"
  }, [_vm.refinedEstimatedPrices ? [_c('PricesList', {
    staticClass: "mb-4",
    attrs: {
      "prices": _vm.refinedEstimatedPrices
    }
  }), _c('v-dialog', {
    attrs: {
      "max-width": "800"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-none mx-auto",
          attrs: {
            "text": "",
            "color": "info"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t('seeOtherEstimatedPrices')) + " ")])], 1)];
      }
    }], null, false, 3960642343),
    model: {
      value: _vm.dialogEstimatedPrices,
      callback: function ($$v) {
        _vm.dialogEstimatedPrices = $$v;
      },
      expression: "dialogEstimatedPrices"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('PriceEstimatesTitle')) + " "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialogEstimatedPrices = false;
      }
    }
  }, [_vm._v(" fas fa-times ")])], 1), _c('v-card-text', [_vm.$appConfig.brand.features.privateSuppliers.length ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PublicSuppliersPrices'),
      "disabled": !_vm.privatePrices
    },
    on: {
      "change": _vm.getEstimatedPricesV2
    },
    model: {
      value: _vm.publicPrices,
      callback: function ($$v) {
        _vm.publicPrices = $$v;
      },
      expression: "publicPrices"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PrivateSuppliersPrices'),
      "disabled": !_vm.publicPrices
    },
    on: {
      "change": _vm.getEstimatedPricesV2
    },
    model: {
      value: _vm.privatePrices,
      callback: function ($$v) {
        _vm.privatePrices = $$v;
      },
      expression: "privatePrices"
    }
  })], 1)], 1) : _vm._e(), _c('PriceEstimatesV2', {
    attrs: {
      "part": _vm.part,
      "prices": _vm.estimatedPrices2
    }
  })], 1)], 1)], 1)] : _c('v-alert', {
    staticClass: "text-center",
    attrs: {
      "type": "info",
      "prominent": "",
      "outlined": "",
      "text": ""
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("onQuote")))]), _c('v-icon', {
    staticClass: "ml-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-file-signature ")])], 1)], 2)], 1) : _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('PriceEstimatesTitle')) + " "), _c('v-dialog', {
    attrs: {
      "transition": "dialog-top-transition",
      "max-width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var on = _ref8.on,
          attrs = _ref8.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-2"
        }, 'v-icon', attrs, false), on), [_vm._v(" fas fa-info-circle ")])];
      }
    }, {
      key: "default",
      fn: function (dialog) {
        return [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('Technologies')) + " "), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function ($event) {
              dialog.value = false;
            }
          }
        }, [_c('v-icon', [_vm._v(" fas fa-times ")])], 1)], 1), _c('v-card-text', [_c('v-simple-table', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('td', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('DMLS')) + " ")]), _c('td', {
                staticClass: "py-4"
              }, [_vm._v(" " + _vm._s(_vm.$t('DMLSDefinition')) + " ")])]), _c('tr', [_c('td', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('MBJ')) + " ")]), _c('td', {
                staticClass: "py-4"
              }, [_vm._v(" " + _vm._s(_vm.$t('MBJDefinition')) + " ")])]), _c('tr', [_c('td', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('FDM')) + " ")]), _c('td', {
                staticClass: "py-4"
              }, [_vm._v(" " + _vm._s(_vm.$t('FDMDefinition')) + " ")])]), _c('tr', [_c('td', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('SLS')) + " ")]), _c('td', {
                staticClass: "py-4"
              }, [_vm._v(" " + _vm._s(_vm.$t('SLSDefinition')) + " ")])]), _c('tr', [_c('td', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('MJF')) + " ")]), _c('td', {
                staticClass: "py-4"
              }, [_vm._v(" " + _vm._s(_vm.$t('MJFDefinition')) + " ")])]), _c('tr', [_c('td', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('SLA')) + " ")]), _c('td', {
                staticClass: "py-4"
              }, [_vm._v(" " + _vm._s(_vm.$t('SLADefinition')) + " ")])])])];
            },
            proxy: true
          }], null, true)
        })], 1)], 1)];
      }
    }], null, false, 4006449277)
  })], 1), _vm.estimatedPrices2 ? _c('v-card-text', [_vm.$appConfig.brand.features.privateSuppliers.length > 0 && _vm.$appConfig.brand.features.accessToBCMNetwork ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PublicSuppliersPrices'),
      "disabled": !_vm.privatePrices
    },
    on: {
      "change": _vm.getEstimatedPricesV2
    },
    model: {
      value: _vm.publicPrices,
      callback: function ($$v) {
        _vm.publicPrices = $$v;
      },
      expression: "publicPrices"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PrivateSuppliersPrices'),
      "disabled": !_vm.publicPrices
    },
    on: {
      "change": _vm.getEstimatedPricesV2
    },
    model: {
      value: _vm.privatePrices,
      callback: function ($$v) {
        _vm.privatePrices = $$v;
      },
      expression: "privatePrices"
    }
  })], 1)], 1) : _vm._e(), _vm.estimatedPrices2 ? _c('PriceEstimatesV2', {
    attrs: {
      "part": _vm.part,
      "prices": _vm.estimatedPrices2
    }
  }) : _vm._e()], 1) : !_vm.part.file && !_vm.part.originalFile ? [_c('v-card-text', {
    staticClass: "px-12 pb-8 pt-4 text-center"
  })] : _vm.part.analysisStatus !== 'FAILED' && _vm.part.fileConversionStatus !== 'ERROR' ? [_c('v-card-text', {
    staticClass: "px-12 pb-8 pt-4 text-center"
  }, [_c('SpinnerBeelse', {
    attrs: {
      "multiple": "",
      "color": "orange",
      "logo": "simple",
      "display-logo": false
    }
  }), _c('p', {
    staticClass: "subtitle-1 mt-6"
  }, [_vm._v(" " + _vm._s(_vm.$t('WaitingForPartAnalysis')) + " ")])], 1)] : [_c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-alert', {
    staticClass: "px-5",
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('AnalysisErrorPartPrices')) + " ")])], 1)]], 2)] : _vm._e(), ((_vm$part3 = _vm.part) === null || _vm$part3 === void 0 ? void 0 : (_vm$part3$homologatio = _vm$part3.homologation) === null || _vm$part3$homologatio === void 0 ? void 0 : (_vm$part3$homologatio2 = _vm$part3$homologatio.prices) === null || _vm$part3$homologatio2 === void 0 ? void 0 : _vm$part3$homologatio2.length) > 0 && ((_vm$part4 = _vm.part) === null || _vm$part4 === void 0 ? void 0 : (_vm$part4$homologatio = _vm$part4.homologation) === null || _vm$part4$homologatio === void 0 ? void 0 : _vm$part4$homologatio.status) === 'REJECTED' ? _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("Prices")))]), _c('v-card-text', [_c('v-alert', {
    attrs: {
      "color": "info",
      "text": "",
      "outlined": ""
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('ThisPartCannotBeOrdered')) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('PricesBelowCorrespondTo', {
    technology: _vm.$t((_vm$part$homologation = _vm.part.homologation) === null || _vm$part$homologation === void 0 ? void 0 : _vm$part$homologation.technology),
    material: _vm.$t((_vm$part$homologation2 = _vm.part.homologation) === null || _vm$part$homologation2 === void 0 ? void 0 : _vm$part$homologation2.material)
  })) + " ")])]), _vm.part.homologation.prices ? _c('PricesList', {
    attrs: {
      "prices": _vm.part.homologation.prices
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.part && (_vm.part.file || _vm.part.originalFile) && _vm.part.dna && _vm.part.dna.completed && _vm.technologiesAndMaterials ? _c('v-card', [_c('v-card-text', [_vm.part && _vm.technologiesAndMaterials ? _c('DnaFormV3', {
    key: 'dnaFormViewer' + _vm.keyComponent,
    ref: "dnaFormViewer",
    attrs: {
      "part": _vm.part,
      "dna-basis": _vm.part.dna,
      "analysis-status": _vm.part.analysisStatus,
      "technologies-and-materials": _vm.technologiesAndMaterials,
      "size": _vm.part.size ? _vm.part.size : {},
      "editor": false,
      "flex-mode": true,
      "bottom-resume": true
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 2)], 1)], 1)], 1) : _vm._e(), _c('DeletePartConfirmationDialog', {
    ref: "deletePartConfirmationDialog",
    attrs: {
      "part": _vm.part
    },
    on: {
      "deletePart": _vm.deletePart
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }