<template lang="html" src="./replacePartFileWithModifications.template.vue"></template>

<style lang="scss" src="./replacePartFileWithModifications.scss"></style>

<script>
import { EventBus, ApiErrorParser, UploadManager, Viewer3d, ProgressSingle, FileConverter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./replacePartFileWithModifications.i18n');

export default {
  name: 'ReplacePartFileWithModifications',
  components: {
    UploadManager,
    Viewer3d,
    ProgressSingle
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    viewerPartFile: {
      type: Object,
      default: null,
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      // Upload control
      canStartModifications: false,
      compressionInProgress: false,
      canClose: false,
      dialogController: false,
      partFile: '',
      partsEditData: [],
      uploadErrorMessage: null,
      uploadStatus: 0,
      // Modifications control
      defaultRotationMatrix: [{matrix: [0,0,0]}],
      tabModel: 0,
    };
  },
  created() {
    EventBus.$on(
      'modifyPartModifications',
      this.modifyBrandPartModifications
    );
  },
  beforeDestroy() {
    EventBus.$off(
      'modifyPartModifications',
      this.modifyBrandPartModifications
    );
  },
  methods: {
    /**
     * SELECTOR FOR UPLOAD NEW PART
     */
    openFileSelector() {
      this.resetData();
      const fileSelector = this.$refs.fileInput;
      fileSelector.value = '';
      fileSelector.click();
    },
    closeDialog() {
      this.dialogController = false;
      setTimeout(this.resetData, 500);
    },
    resetData() {
      this.uploadErrorMessage = null;
      this.canClose = false;
      this.canStartModifications = false;
      this.tabModel = 0;
      this.uploadStatus = 0;
      this.partFile = '';
      this.partsEditData = [];
    },
    preparePartsEditor() {
      const fileName = this.partFile[0].name.replace(/\.stl|\.3mf|\.stp|\.step|\.obj|\.iges|\.igs/i, '');
      this.partsEditData.push({
        fileID: 0,
        name: fileName,
        description: '',
        internalReference: '',
      });
      this.uploadStatus = 0;
      this.uploadErrorMessage = null;
      this.dialogPartsFileUploadStatus = true;
      this.dialogController = true;
      this.uploadPartFile();
    },
    /**
     * PREPARE UPLOAD FILE AND CHECK HIS EXTENSION
     */
    partsUploadFilesChanges() {
      const fileSelector = this.$refs.fileInput;
      if (fileSelector.value && fileSelector.files.length > 0) {
        this.partFile = fileSelector.files;
        const files = this.partFile[0].name;
        const regex = /(.stl|.3mf|.stp|.step|.obj|.iges|.igs)$/i;
        if (regex.test(files)) {
          this.preparePartsEditor();
        } else {
          this.$notification.notify('DANGER',this.$t('wrongExt'));
        }
      }
    },
    /**
     * UPLOAD NEW PART
     */
    uploadPartFile() {
      const fileToUpload = this.partFile[this.partsEditData[0].fileID];
      const fileExt = fileToUpload.name.split('.').pop().toLowerCase();

      if(fileExt === 'stl') {
        const self = this;
        this.compressionInProgress = true;
        /**
         * Convert to BLS
         */
        let buffer = null;
        let fileName = fileToUpload.name;
        fileName = fileName.substring(0, fileName.length - 4);

        const reader = new FileReader();
        reader.onloadend = function() {
          buffer = reader.result;

          FileConverter.convertFile(buffer, 'stl', 'bls').then(newData => {
            self.compressionInProgress = false;
            const newFileToUpload = new File([newData], fileName + '.bls');

            self.$refs.UploadManagerRef.uploadPart(
              newFileToUpload,
              self.part.brand_id,
              self.part._id
            );

          });
        };

        reader.readAsArrayBuffer(fileToUpload);

      } else {
        this.$refs.UploadManagerRef.uploadPart(
          fileToUpload,
          this.part.brand_id,
          this.part._id
        );
      }
    },
    uploadSucceeded() {
      this.$emit('PartFileUploaded');
      this.uploadStatus = 50;
      setTimeout(this.checkPartFile, 1000);
    },
    checkPartFile() {
      if(!this.viewerPartFile) {
        //Simulating progress over 25 seconds
        if(this.uploadStatus < 99) {
          this.uploadStatus += 1;
        }
        setTimeout(this.checkPartFile, 500);
      } else {
        this.uploadStatus = 100;
        this.canStartModifications = true;
      }
    },
    checkProgress(status) {
      // We stop at 99% because we will wait for the request response
      this.uploadStatus = Math.round(49 * (status / 100));
    },
    listUploadError(error) {
      let errorMessage = this.$t('UploadError');
      if(error.response) {
        if(JSON.parse(error.response)?.message === 'Part file is too large') {
          errorMessage = this.$t('PartTooLarge');
        }
      }
      this.uploadErrorMessage = errorMessage;
      this.canClose = true;
    },
    startModifications() {
      this.tabModel = 1;
    },
    activateModificationsTool() {
      /**
       * In case we have an error during the upload
       */
      if(this.uploadErrorMessage && this.canClose) {
        this.closeDialog();
      }else{
        /**
         * No error during upload, the 3d viewer is displayed
         */
        if(this.$refs.viewer3d && this.$refs.viewer3d.displayModifications) {
          this.$refs.viewer3d.displayModifications();
        }
      }
    },
    checkExitStatus(payload) {
      if(!this.$refs.viewer3d) {
        return;
      }
      if(payload === true) {
        this.closeDialog();
      }
    },
    modifyBrandPartModifications(uuid, modifications) {
      if(!this.$refs.viewer3d || !this.$refs.viewer3d.uuid || uuid !== this.$refs.viewer3d.uuid) {
        return;
      }
      
      const modifyBrandPartModificationsBody = new this.$BcmModel.ModifyBrandPartModificationsBody(modifications);

      this.$apiInstance.modifyBrandPartModifications(
        this.part.brand_id,
        this.part._id,
        modifyBrandPartModificationsBody
      ).then((response) => {
        this.closeDialog();
        this.$notification.notify('SUCCESS',this.$t('PartModificationsSaved'), {timeout: 6000});
      }, (error) => {
        this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
      });
    },
  },
};
</script>
