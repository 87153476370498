<template lang="html" src="./pagePart.template.vue"></template>

<style lang="scss" src="./pagePart.scss"></style>

<script>
import {
  BtnMenu,
  DeletePartConfirmationDialog,
  DnaFormV3,
  DownloadPartFile,
  DownloadBrandPartOriginalFile,
  DownloadBrandPartSerialNumberFile,
  DownloadPartViewer3dFile,
  EventBus,
  FileExtensionModal,
  FormPart,
  HomologationStepperV2,
  PartSharingManager,
  PartTags,
  PricesList,
  ApiErrorParser,
  PriceEstimates,
  PriceEstimatesV2,
  Viewer3d,
  ViewerCriticalDimension,
  ViewerObjectsSplitter,
  ViewerSerialNumber,
  SpinnerBeelse,
  RepeatabilityIndex,
} from '@cloudmanufacturingtechnologies/portal-components';

import SupportEmail from '../../components/supportEmail/SupportEmail';
import MenuActions from '../../components/menuActions/MenuActions';
import AddToBasket from '../../components/addToBasket/AddToBasket';
import ReplacePartFile from '../../components/replacePartFile/ReplacePartFile';
import ReplacePartFileWithModifications from '../../components/replacePartFileWithModifications/ReplacePartFileWithModifications';
import PartAttachmentsManager from '../../components/partAttachmentsManager/PartAttachmentsManager';

const i18nData = require('./pagePart.i18n');

export default {
  name: 'PagePart',
  components: {
    AddToBasket,
    BtnMenu,
    DeletePartConfirmationDialog,
    DownloadPartFile,
    DnaFormV3,
    DownloadPartViewer3dFile,
    FileExtensionModal,
    FormPart,
    HomologationStepperV2,
    MenuActions,
    PartSharingManager,
    PartAttachmentsManager,
    PartTags,
    PriceEstimates,
    PriceEstimatesV2,
    PricesList,
    RepeatabilityIndex,
    ReplacePartFile,
    ReplacePartFileWithModifications,
    SupportEmail,
    SpinnerBeelse,
    Viewer3d,
    ViewerSerialNumber,
    ViewerObjectsSplitter,
    ViewerCriticalDimension,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Global
       */
      part: null,
      brandPartOrders: [],
      brandPartQuotes: [],
      /**
       * File
       */
      downloadChoices: [],
      downloadSerialNumberChoices: null,
      partDownloadName: '',
      partSerialNumberDownloadName: '',
      partFileDataURL: {},
      partSerialNumberFileDataURL: {},
      /**
       * Viewer 3D
       */
      viewer3dFile: null,
      viewerFullscreen: false,
      displayViewer: true,
      /**
       * DNA
       */
      dnaModal: false,
      technologiesAndMaterials: null,
      warningCloseDnaForm: false,
      keyComponent: 0,
      /**
       * PRICES
       */
      estimatedPrices: null,
      estimatedPrices2: null,
      dialogEstimatedPrices: false,
      refinedEstimatedPrices: null,
      privateSuppliersEstimatedPrices: [],
      publicPrices: true,
      privatePrices: true,
      /**
       * Part tags
       */
      brandPartsTags: [],
      /**
       * OTHER
       */
      loadingFile: false,
      dialogPartAttachmentsManager: false,
      modalStartHomologation: false,
      requestSendedStartHomologation: false,
      padlockClosed: false,
      partModifications: null,
      displayProposition: false,
    };
  },
  created() {
    EventBus.$on('modifyPartOrientation', this.modifyBrandPartOrientation);
    EventBus.$on('modifyPartModifications', this.modifyBrandPartModifications);
    this.getBrandPart();
    if(!this.$appConfig.brand.features.accessToBCMNetwork) {
      this.publicPrices = false;
    }
  },
  beforeDestroy() {
    EventBus.$off('modifyPartOrientation', this.modifyBrandPartOrientation);
    EventBus.$off('modifyPartModifications', this.modifyBrandPartModifications);
  },
  computed: {
    displayObjectsConfirmation() {
      return (this.part?.analysisInformation?.outerShells >= 2 || this.part?.analysisInformation?.innerShells >= 1) && !this.part?.acceptManufacturingWithMultipleObjects;
    },
    partIsReadyForHomologation() {
      return !this.dnaModal && this.part.file && this.part.dna && this.part.dna.completed
        && (!this.$appConfig.brand.features.enableSerialNumber || !this.part.serialNumber || this.part.serialNumber.csgStatus === 'VALID')
        && (this.part.acceptManufacturingWithMultipleObjects || ( this.part?.analysisInformation?.outerShells === 1 && this.part?.analysisInformation?.innerShells === 0));
    },
    anyAnalysisInProgress() {
      return (
        this.part.analysisStatus === 'IDLE' || this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING' 
        || (this.part.originalFile && (this.part.fileConversionStatus === 'IN_PROGRESS' || this.part.fileConversionStatus === 'IDLE')) 
        || (this.part.serialNumber && (this.part.serialNumber.csgStatus === 'IN_PROGRESS' || this.part.serialNumber.csgStatus === 'IDLE' || this.part.serialNumber.csgStatus === 'ANALYSIS_IDLE' || this.part.serialNumber.csgStatus === 'ANALYSIS_IN_PROGRESS'))
      );
    },
    displayPricesDuringRejection() {
      return part?.homologation?.prices?.length > 0 
        && (part?.homologation?.rejectionHistory?.length > 0 && ['REJECTED', 'SUPPLIER_QUOTATION', 'VALIDATION'].includes(part?.homologation?.status));
    },
    editable() {
      return !!this.part && this.part.brand_id === this.$appConfig.brand._id && (this.part.status === 'NEW' || this.part.status === 'HOMOLOGATED'
        || (this.part.status === 'HOMOLOGATION' && ['NEW', 'REJECTED', 'READY', 'TESTING'].includes(this.part.homologation?.status)));
    }
  },
  methods: {
    updateDownloadChoices() {
      this.downloadChoices = [
        {
          text: '.STL',
          value: 'stl'
        },
        {
          text: '.3MF',
          value: '3mf'
        }
      ];
      if(this.part?.serialNumber) {
        this.downloadSerialNumberChoices = [...this.downloadChoices];
      }
      if(this.part?.originalFile) {
        this.downloadChoices.push({
          value: this.part.originalFile.extension,
          text: `.${this.part.originalFile.extension.toUpperCase()}`
        });
      }
    },
    init() {
      if(this.$appConfig.brand._id !== this.part.brand_id) {
        /**
         * Shared Part
         */
        this.viewerFullscreen = true;
      }

      this.updateDownloadChoices();
      if (this.part.homologation && this.part.modifications) {
        this.getBrandPartModifications();
      } else {
        this.partModifications = [];
      }
      if(this.part.size) {
        this.getBrandTechnologiesAndMaterials();
      }

      if (this.part.size 
        && !this.anyAnalysisInProgress
        && (this.part.status === 'NEW' || (this.part.status === 'HOMOLOGATION' && this.part.homologation.status === 'REJECTED'))) {
        this.getEstimatedPrices();
        this.getEstimatedPricesV2();
        this.getBrandPartRefinedEstimatedPrices();
        if(this.part.dna?.technology && this.part.dna?.material) {
          this.getBrandPartPrivateSuppliersEstimatedPrices();
        }
      } else {
        this.estimatedPrices = null;
        this.refinedEstimatedPrices = null;
        this.privateSuppliersEstimatedPrices = [];
      }
      if (!this.viewer3dFile && this.part.viewer3dFile) {
        this.getPartViewer3dFile();
      }

      this.getBrandPartOrders();
      this.getBrandPartQuotes();
      this.getBrandPartsTags();
    },
    /**
     * GET BRAND PART
     */
    getBrandPart() {
      this.$apiInstance
        .getBrandPart(this.$appConfig.currentBrand, this.$route.params.partUUID)
        .then(
          (data) => {

            this.part = data;
            this.init();

            if (this.$router.currentRoute.name === 'Part' && this.anyAnalysisInProgress) {
              setTimeout(this.updateBrandPartStatus, 5000);
            }

          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    updateBrandPartStatus() {
      this.$apiInstance
        .getBrandPart(this.$appConfig.currentBrand, this.$route.params.partUUID)
        .then(
          (data) => {
            this.part = data;

            if (!this.viewer3dFile && this.part.viewer3dFile) {
              this.getPartViewer3dFile();
            }

            if(this.$router.currentRoute.name === 'Part' && this.anyAnalysisInProgress) {
              setTimeout(this.updateBrandPartStatus, 5000);
            } else {
              this.init();
            }
          });
    },
    /**
     * GET BRAND PART ORDERS
     */
    getBrandPartOrders() {
      this.$apiInstance
        .getBrandPartOrders(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID
        )
        .then(
          (brandPartOrdersData) => {
            this.brandPartOrders = brandPartOrdersData;
          },
          /**
           * ERROR GET BRAND PART ORDER
           */
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * GET BRAND PART QUOTES
     */
    getBrandPartQuotes() {
      this.$apiInstance
        .getBrandPartQuotes(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID
        )
        .then(
          (brandPartQuotesData) => {
            this.brandPartQuotes = brandPartQuotesData;
          },
          /**
           * ERROR GET BRAND PART ORDER
           */
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    getEstimatedPrices() {
      this.estimatedPrices = null;
      this.$apiInstance
        .getBrandPartEstimatedPrices(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID
        )
        .then(
          (estimatedPrices) => {
            this.estimatedPrices = estimatedPrices;
          }
        );
    },
    getEstimatedPricesV2() {
      let source = 'both';

      if(!this.publicPrices) {
        source = 'private';
      }

      if(!this.privatePrices) {
        source = 'public';
      }

      this.$apiInstance
        .getBrandPartEstimatedPricesV2(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID,
          {source: source}
        )
        .then(
          (estimatedPrices) => {
            this.estimatedPrices2 = estimatedPrices;
          }
        );
    },
    getBrandPartRefinedEstimatedPrices() {
      this.refinedEstimatedPrices = null;
      this.$apiInstance
        .getBrandPartRefinedEstimatedPrices(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID
        )
        .then(
          (refinedEstimatedPrices) => {
            this.refinedEstimatedPrices = refinedEstimatedPrices;
          },
          (error) => {}
        );
    },
    getBrandPartPrivateSuppliersEstimatedPrices() {
      this.$apiInstance.getBrandPartPrivateSuppliersEstimatedPrices(
        this.$route.params.brandUUID,
        this.$route.params.partUUID
      ).then(data => {
        this.privateSuppliersEstimatedPrices = data;
      });
    },
    getBrandPartModifications() {
      if(!this.part.modifications) {
        this.partModifications = [];
      } else {
        this.$apiInstance
          .getBrandPartModifications(
            this.$appConfig.currentBrand,
            this.$route.params.partUUID
          )
          .then(
            (data) => {
              this.partModifications = data;
            },
            (error) => {
              //Modifications not specified
              this.partModifications = [];
            }
          );
      }
    },
    getPartFileForViewerSplitter() {
      if(!this.partFileDataURL['stl']) {
        DownloadPartFile.downloadPartFile(
          this.$apiInstance,
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          'stl'
        )[0].then((response) => {
          this.$set(this.partFileDataURL,'stl',{
            extension: response.extension,
            buffer: Buffer.from(response.buffer),
          });
        });
      }
    },
    /**
     * GET PART FILE
     */
    getPartFile(extension = null) {
      this.loadingFile = true;
      extension = extension || 'stl';

      this.partDownloadName = this.part.name + '.' + extension;

      if(!this.partFileDataURL[extension]) {

        let promise, req = null;

        if(['step', 'obj', 'iges'].includes(extension)) {
          [promise, req] = DownloadBrandPartOriginalFile.downloadBrandPartOriginalFile(
            this.$apiInstance,
            this.$route.params.brandUUID,
            this.$route.params.partUUID
          );
        } else {
          [promise, req] = DownloadPartFile.downloadPartFile(
            this.$apiInstance,
            this.$route.params.brandUUID,
            this.$route.params.partUUID,
            extension
          );
        }

        promise.then((response) => {
          this.arrayBuffer = response.buffer;
          const byteArray = new Uint8Array(this.arrayBuffer);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });
          this.partFileDataURL[extension] = URL.createObjectURL(blob);
          this.$refs.downloadFileButton.href = this.partFileDataURL[extension];
          this.loadingFile = false;
          setTimeout(() => {
            this.$refs.downloadFileButton.click();
          }, 250);
        });
        this.$downloadProgress.addDownload(req, this.partDownloadName, promise);

      } else {

        this.$refs.downloadFileButton.href = this.partFileDataURL[extension];
        setTimeout(() => {
          this.$refs.downloadFileButton.click();
        }, 250);
        setTimeout(() => {
          this.loadingFile = false;
        }, 1000);

      }
    },
    getPartSerialNumberFile(extension = null) {
      this.loadingFile = true;
      extension = extension || 'stl';

      this.partSerialNumberDownloadName = this.part.name + '_00000-00.' + extension;

      if(!this.partSerialNumberFileDataURL[extension]) {

        const [promise, req] = DownloadBrandPartSerialNumberFile.downloadBrandPartSerialNumberFile(
          this.$apiInstance,
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          extension
        );
        
        promise.then((response) => {
          this.arrayBuffer = response.buffer;
          const byteArray = new Uint8Array(this.arrayBuffer);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });
          this.partSerialNumberFileDataURL[extension] = URL.createObjectURL(blob);
          this.$refs.downloadSerialNumberFileButton.href = this.partSerialNumberFileDataURL[extension];
          this.loadingFile = false;
          setTimeout(() => {
            this.$refs.downloadSerialNumberFileButton.click();
          }, 250);
        });
        this.$downloadProgress.addDownload(req, this.partSerialNumberDownloadName, promise);

      } else {

        this.$refs.downloadSerialNumberFileButton.href = this.partSerialNumberFileDataURL[extension];
        setTimeout(() => {
          this.$refs.downloadSerialNumberFileButton.click();
        }, 250);
        setTimeout(() => {
          this.loadingFile = false;
        }, 1000);

      }
    },
    /**
     * GET PART VIEWER3D FILE
     */
    getPartViewer3dFile() {
      this.viewer3dFile = null;
      const oReq = DownloadPartViewer3dFile.downloadPartViewer3dFile(
        this.$apiInstance,
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        new Date(this.part.viewer3dFile.created).getTime()
      );
      oReq.onloadend = () => {
        this.viewer3dFile = {
          extension: 'blsv',
          buffer: Buffer.from(oReq.response),
        };
      };
    },
    /**
     * MODIFY BRAND PART
     */
    saveBrandPartInformation(part) {
      const modifyBrandPartBody = new this.$BcmModel.ModifyBrandPartBody(
        part.name,
        part.description,
        part.internalReference
      );
      this.$apiInstance
        .modifyBrandPart(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          modifyBrandPartBody
        )
        .then(
          (partData) => {
            this.part = partData;
            this.getBrandPart();
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS', this.$t('PartSuccessfullyModified'));
          },
          (error) => {
            /**
             * ERROR MODIFY BRAND PART
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    acceptManufacturingWithMultipleObjects() {
      this.$apiInstance.acceptBrandPartManufacturingWithMultipleObjects(
        this.$route.params.brandUUID,
        this.$route.params.partUUID
      ).then(
        (partData) => {
          this.getBrandPart();
          this.$notification.notify('SUCCESS', this.$t('PartMultipleObjectsValidated'));
        },
        (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    modifyBrandPartStatus(status) {
      const modifyBrandPartStatusBody = new this.$BcmModel.ModifyBrandPartStatusBody(
        status
      );
      this.$apiInstance
        .modifyBrandPartStatus(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          modifyBrandPartStatusBody
        )
        .then(() => {
          this.$refs.homologationStepper.$refs.startHomologationDialogRef.close();
          this.getBrandPart();
        })
        .catch((err) => {
          /**
           * ERROR MODIFY PART STATUS
           */
          this.$refs.homologationStepper.$refs.startHomologationDialogRef.stopLoading();
          this.$notification.notify('DANGER', ApiErrorParser.parse(err));
        });
    },
    sendPartHomologationToPrivateSupplier(supplierUUID) {
      this.$apiInstance.sendBrandPartHomologationToPrivateSupplier(
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        supplierUUID
      ).then(() => {
        this.$refs.homologationStepper.$refs.startHomologationDialogRef.close();
        this.getBrandPart();
      })
        .catch((err) => {
          /**
           * ERROR MODIFY PART STATUS
           */
          this.$refs.homologationStepper.$refs.startHomologationDialogRef.stopLoading();
          this.$notification.notify('DANGER', ApiErrorParser.parse(err));
        });
    },
    modifyBrandPartModifications(uuid, modifications) {
      const modifyBrandPartModificationsBody = new this.$BcmModel.ModifyBrandPartModificationsBody(
        modifications
      );

      this.$apiInstance
        .modifyBrandPartModifications(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID,
          modifyBrandPartModificationsBody
        )
        .then(
          (response) => {
            this.partModifications = response;
            //Reloading viewer
            if(this.part.viewer3dFile) {
              this.getPartViewer3dFile();
            }
            this.$notification.notify('SUCCESS',this.$t('PartModificationsSaved', { timeout: 6000 }));
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    modifyBrandPartCriticalDimensions(criticalDimensions) {
      const modifyBrandPartCriticalDimensionsBody = new this.$BcmModel.ModifyBrandPartCriticalDimensionsBody(
        criticalDimensions
      );
      this.$apiInstance
        .modifyBrandPartCriticalDimensions(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID,
          modifyBrandPartCriticalDimensionsBody
        )
        .then(
          (data) => {
            this.$notification.notify('SUCCESS',this.$t('SuccessCriticalDimensions'));
            this.part.criticalDimensions = data.criticalDimensions;
            this.$refs.viewerCriticalDimension.close();
            this.displayViewer = false;
            setTimeout(() => {
              this.displayViewer = true;
            }, 100);
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)), { timeout: 10000 });
          }
        );
    },
    modifyBrandPartOrientation(uuid, rotations) {
      const modifyBrandPartOrientationBody = new this.$BcmModel.ModifyBrandPartOrientationBody(
        rotations
      );
      this.$apiInstance
        .modifyBrandPartOrientation(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID,
          modifyBrandPartOrientationBody
        )
        .then(
          (data) => {
            this.$notification.notify('SUCCESS',this.$t('SuccessReOrientation'));
            this.part.rotationHistory = data.rotationHistory;
            this.displayViewer = false;
            setTimeout(() => {
              this.displayViewer = true;
            }, 100);
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)), { timeout: 12000 });
          }
        );
    },
    modifyBrandPartSerialNumberSettings(serialNumberSettings) {
      const modifyBrandPartSerialNumberSettingsBody = this.$BcmModel.ModifyBrandPartSerialNumberSettingsBody.constructFromObject({
        p1: serialNumberSettings.points[0], 
        p2: serialNumberSettings.points[1], 
        normal: serialNumberSettings.normal, 
        size: serialNumberSettings.parameters.size,
        height: serialNumberSettings.parameters.height,
        mode: serialNumberSettings.parameters.mode
      });
      this.$apiInstance.modifyBrandPartSerialNumberSettings(
        this.$appConfig.currentBrand,
        this.$route.params.partUUID,
        modifyBrandPartSerialNumberSettingsBody
      ).then(data => {
        this.part = data;
        this.$refs.viewerSerialNumber.close();
        this.displayViewer = false;
        setTimeout(() => {
          this.displayViewer = true;
        }, 100);
        /**
         * CSG Status is 'IN_PROGRESS' at this point so we can just call getBrandPart in a few seconds
         */
        setTimeout(this.getBrandPart, 5000);
        this.$notification.notify('SUCCESS',this.$t('PartSerialNumberSettingsSaved'), { timeout: 10000 });
      }).catch(err => {
        this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(err)));
      });
    },
    removeCriticalDimensions() {
      const modifyBrandPartCriticalDimensionsBody = new this.$BcmModel.ModifyBrandPartCriticalDimensionsBody([]);
      this.$apiInstance
        .modifyBrandPartCriticalDimensions(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID,
          modifyBrandPartCriticalDimensionsBody
        )
        .then(
          (data) => {
            this.$notification.notify('SUCCESS',this.$t('CriticalDimensionsRemoved'));
            this.part.criticalDimensions = data.criticalDimensions;
            this.displayViewer = false;
            setTimeout(() => {
              this.displayViewer = true;
            }, 100);
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    removeSerialNumber() {
      const modifyBrandPartSerialNumberSettingsBody = new this.$BcmModel.ModifyBrandPartSerialNumberSettingsBody(6,0.8,'UNION');
      this.$apiInstance.modifyBrandPartSerialNumberSettings(
        this.$appConfig.currentBrand,
        this.$route.params.partUUID,
        modifyBrandPartSerialNumberSettingsBody
      ).then(data => {
        this.$notification.notify('SUCCESS',this.$t('PartSerialNumberRemoved', { timeout: 6000 }));
        this.part.serialNumberSettings = data.serialNumberSettings;
        this.part.serialNumber = data.serialNumber;
        this.displayViewer = false;
        setTimeout(() => {
          this.displayViewer = true;
        }, 100);
      }).catch(err => {
        this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(err)));
      });
    },
    animateIconHomologationModalIn() {
      if (!this.requestSendedStartHomologation) {
        this.padlockClosed = true;
      }
    },
    animateIconHomologationModalOut() {
      if (!this.requestSendedStartHomologation) {
        this.padlockClosed = false;
      }
    },
    switchFullscreen(e) {
      this.viewerFullscreen = e;
    },
    /**
     * CriticalDimensions
     * Part critical dimensions updated
     */
    saveBrandPartCriticalDimensions(criticalDimensions) {
      const modifyBrandPartCriticalDimensionsBody = new this.$BcmModel.ModifyBrandPartCriticalDimensionsBody(
        criticalDimensions
      );
      this.$apiInstance
        .modifyBrandPartCriticalDimensions(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          modifyBrandPartCriticalDimensionsBody
        )
        .then(
          (part) => {
            this.part = part;
            this.$notification.notify('SUCCESS',this.$t('SuccessCriticalDimensions'));
            if (this.$refs.viewerCriticalDimension) this.$refs.viewerCriticalDimension.refresh();
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    openDnaFormDialog() {
      this.$refs.dnaDialog.isActive = true;
    },
    openCriticalDimensionsTool() {
      this.$refs.viewerCriticalDimension.open();
    },
    /**
     * Component part tags
     */
    getBrandPartsTags() {
      this.$apiInstance.getBrandPartsTags(this.$appConfig.currentBrand).then(
        (brandPartsTagsData) => {
          this.brandPartsTags = brandPartsTagsData;
        },
        (error) => {
          ApiErrorParser.parse(error);
        }
      );
    },
    saveBrandPartTags(tags) {
      const brandPartTags = new this.$BcmModel.ModifyBrandPartTagsBody(tags);
      this.$apiInstance
        .modifyBrandPartTags(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID,
          brandPartTags
        )
        .then(
          (partData) => {
            this.part = partData;
            this.getBrandPartsTags();
            this.$notification.notify('SUCCESS',this.$t('TagSuccessfullyModified'));
          },
          (error) => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        );
    },
    /**
     * End Component part tags
     */
    partFileUploaded() {
      this.technologiesAndMaterials = null;
      this.viewer3dFile = null;
      this.partViewer3dFile = '';
      this.getBrandPart();
    },
    /**
     * Start Homologation stepper methods
     */
    openModal(typeOf) {
      switch (typeOf) {
      case 'fileModal':
        if (!this.part.homologation) {
          this.$refs.replacePartFile.openFileSelector();
        } else {
          this.$refs.replacePartFileWithModifications.openFileSelector();
        }
        break;
      case 'dnaModal':
        this.dnaModal = true;
        break;
      case 'ModalPartTags':
        this.$refs.partTags.displayModalPartTag = true;
        break;
      case 'ModalPartInformation':
        this.$refs.partInformation.displayModalPartInformation = true;
        break;
      case 'criticalDimensionsModal':
        this.$refs.viewerCriticalDimension.open();
        break;
      case 'serialNumberModal':
        this.$refs.viewerSerialNumber.open();
        break;
      case 'SupportModal':
        this.$refs.support.dialogSupportEmail = true;
        break;
      case 'supportDesignOffice':
        this.$refs.support.dialogSupportEmail = true;
        break;
      case 'fileExtensionModal':
      case 'downloadModal':
        this.$refs.fileExtensionModalRef.open();
        break;
      case 'deletePartModal':
        this.$refs.deletePartConfirmationDialog.open();
        break;
      case 'viewerObjectsSplitter':
        this.getPartFileForViewerSplitter();
        this.$refs.viewerObjectsSplitterRef.open();
        break;
      case 'attachmentsManager':
        this.dialogPartAttachmentsManager = true;
        break;
      default:
        break;
      }
    },
    updateStatus(status) {
      switch (status) {
      case 'startHomologation':
        const modifyBrandPartStatusBody = new this.$BcmModel.ModifyBrandPartStatusBody();
        modifyBrandPartStatusBody.status = 'HOMOLOGATION';
        this.$apiInstance
          .modifyBrandPartStatus(
            this.$appConfig.currentBrand,
            this.$route.params.partUUID,
            modifyBrandPartStatusBody
          )
          .then(
            () => {
              setTimeout(
                function() {
                  this.$refs.homologationStepper.modalStartHomologation = false;
                  this.getBrandPart();
                  setTimeout(
                    function() {
                      this.$refs.homologationStepper.requestSendedStartHomologation = false;
                    }.bind(this),
                    1000
                  );
                }.bind(this),
                1000
              );
            },
            (error) => {
              this.$notification.notify('ERROR', ApiErrorParser.parse(error));
            }
          );
        break;
      case 'new':
        this.modifyBrandPartStatus('NEW');
        break;
      case 'homologated':
        this.modifyBrandPartStatus('HOMOLOGATED');
        break;
      default:
        break;
      }
    },
    goTo(route, uuid) {
      switch (route) {
      case 'basket':
        this.$router.push({ name: 'Basket' });
        break;
      case 'pageQuotes':
        this.$router.push({
          name: 'Quote',
          params: {
            brandUUID: `${this.$appConfig.currentBrand}`,
            quoteUUID: `${uuid}`,
          },
        });
        break;
      case 'pageOrders':
        this.$router.push({
          name: 'Order',
          params: {
            brandUUID: `${this.$appConfig.currentBrand}`,
            orderUUID: `${uuid}`,
          },
        });
        break;
      }
    },
    deletePart() {
      if(this.part.status !== 'NEW') {
        const modifyBrandPartStatusBody = new this.$BcmModel.ModifyBrandPartStatusBody('NEW');
        this.$apiInstance
          .modifyBrandPartStatus(
            this.$route.params.brandUUID,
            this.$route.params.partUUID,
            modifyBrandPartStatusBody
          )
          .then(() => {
            this.$apiInstance
              .deleteBrandPart(
                this.$route.params.brandUUID,
                this.$route.params.partUUID
              )
              .then(
                (deletePartData) => {
                  this.$notification.notify('SUCCESS',this.$t('PartSuccessfullyDeleted', {
                    partName: deletePartData,
                  }));
                  EventBus.$emit('globalEventBasketMenuUpdateBadge');
                  this.$router.push({name: 'Warehouse', params: {brandUUID: `${this.$appConfig.currentBrand}`}});
                },
                (error) => {
                  /**
                   * ERROR DELETE PART
                   */
                  this.$notification.notify('ERROR',ApiErrorParser.parse(error));
                }
              );
          })
          .catch((err) => {
            /**
             * ERROR MODIFY PART STATUS
             */
            ApiErrorParser.parse(err);
          });
      } else {
        this.$apiInstance
          .deleteBrandPart(
            this.$route.params.brandUUID,
            this.$route.params.partUUID
          )
          .then(
            (deletePartData) => {
              this.$notification.notify('SUCCESS',this.$t('PartSuccessfullyDeleted', {
                partName: deletePartData,
              }));
              EventBus.$emit('globalEventBasketMenuUpdateBadge');
              this.$router.push({name: 'Warehouse', params: {brandUUID: `${this.$appConfig.currentBrand}`}});
            },
            (error) => {
              /**
               * ERROR DELETE PART
               */
              this.$notification.notify('ERROR',ApiErrorParser.parse(error));
            }
          );
      }
    },
    cancelDnaForm() {
      this.getBrandPart();
    },
    closeDnaModal() {
      if (this.$refs && this.$refs.dnaForm && this.$refs.dnaForm.editableData) {
        this.warningCloseDnaForm = true;
      } else {
        this.dnaModal = false;
        this.dialogClosed();
      }
    },
    getBrandTechnologiesAndMaterials() {
      this.$apiInstance
        .getBrandTechnologiesAndMaterialsCompatibilities(this.$appConfig.currentBrand, {size: this.part.size})
        .then(
          (technologiesAndMaterials) => {
            this.technologiesAndMaterials = technologiesAndMaterials;
          },
          /**
           * ERROR GET ALL TECHNOLOGIES AND MATERIALS
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          });
    },
    dnaModalNextStep() {
      this.dnaModal = false;
      this.dialogClosed();
    },
    dialogClosed() {
      if(this.partIsReadyForHomologation) {
        this.$refs.homologationStepper.openModalHomologation();
      }
    },
    saveDNA(dna, completedDna) {
      this.$apiInstance
        .modifyBrandPartDNA(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID,
          dna
        )
        .then(
          () => {
            if (completedDna) {
              this.getBrandPart();
              this.$refs.dnaForm.savedResult(false);
              // this.keyComponent += 1;
              // setTimeout(() => this.$refs.dnaForm.activatesTheAutomaticClosing(), 500);
        
            }
          },
          (error) => {
            this.$refs.dnaForm.savedResult(true);
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        );
    },
    viewerRenderingReady() {
      if(!this.part.image) {
        setTimeout(() => {
          this.$refs.viewer3dRef.captureImage();
        }, 1000);
      }
    },
    /**
     * End Homologation stepper methods
     */
    addBrandPartImage(data) {
      const file = new File([data], `${this.part.name}.png`, {
        type: 'image/png',
      });

      this.$apiInstance.addBrandPartImage(
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        {file: file}
      ).then(data => {
        if(this.$refs.viewer3dRef?.takingPicture) {
          this.$refs.viewer3dRef.displayPictureTool();
        }
        if(this.part.image) {
          this.$notification.notify('SUCCESS', this.$t('SavedPartImage'));
        }
      }, error => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getBrandPart();
      });
    },
    /**
     * This function will call 3 times the API :
     * 1 - initBrandPartHomologation
     * 2 - modifyBrandPartStatus 
     * 3 - updateBrandPartQuoteStatus
     */
    updatePartQuotes(publicHomologation, supplierUUID) {
      const initBrandPartHomologationBody = new this.$BcmModel.InitBrandPartHomologationBody(
        publicHomologation,
        supplierUUID
      );

      const modifyBrandPartStatusBody = new this.$BcmModel.ModifyBrandPartStatusBody(
        'HOMOLOGATION'
      );

      // 1 - InitBrandPartHomologation
      this.$apiInstance.initBrandPartHomologation(
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        initBrandPartHomologationBody
      ).then(data => {
        // 2 - ModifyBrandPartStatus(Homologation)
        this.$apiInstance.modifyBrandPartStatus(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          modifyBrandPartStatusBody
        ).then(data => {
          // 3 - UpdateBrandPartQuoteStatus
          this.$apiInstance.updateBrandPartQuotesStatus(
            this.$route.params.brandUUID,
            this.$route.params.partUUID
          ).then(data => {
            this.part.homologation = data.homologation;
            this.$notification.notify('SUCCESS', this.$t('QuotesHaveBeenUpdated'));
          }, error => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }).finally(() => {
            this.part = null;
            this.getBrandPart();
          });
        }, error => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          this.getBrandPart();
        });
      }, error => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        this.getBrandPart();
      });


    },
    selectSupplierAndAddPartToBasket(publicHomologation, supplierUUID, quantity) {
      if(this.part.status === 'NEW') {

        const initBrandPartHomologationBody = new this.$BcmModel.InitBrandPartHomologationBody(
          publicHomologation,
          supplierUUID
        );

        this.$apiInstance.initBrandPartHomologation(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          initBrandPartHomologationBody
        ).then(data => {
          this.part.homologation = data.homologation;
          this.addPartToBasket(quantity);
        }, error => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        });

      } else {
        this.addPartToBasket(quantity);
      }
    },
    addPartToBasket(quantity) {
      if(!this.part.homologation) {
        return;
      }

      const addPartToUserBrandBasketBody = new this.$BcmModel.AddPartToUserBrandBasketBody(
        quantity
      );

      this.$apiInstance.addPartToUserBrandBasket(
        this.$route.params.partUUID,
        this.$appConfig.user._id,
        this.$route.params.brandUUID,
        addPartToUserBrandBasketBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('PartAddedToBasket', {quantity: quantity}));
        this.$basket.getBasket();
      }, error => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      });
    }
  },
};
</script>
