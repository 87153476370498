var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-replacePartFileWithModifications mr-2"
  }, [_c('UploadManager', {
    ref: "UploadManagerRef",
    on: {
      "progress": _vm.checkProgress,
      "error": _vm.listUploadError,
      "success": _vm.uploadSucceeded
    }
  }), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".stl,.3mf,.step,.stp,.obj,.iges,.igs"
    },
    on: {
      "change": _vm.partsUploadFilesChanges
    }
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": _vm.tabModel === 0 ? '350px' : '80%',
      "content-class": "module-replacePartFileWithModifications"
    },
    model: {
      value: _vm.dialogController,
      callback: function ($$v) {
        _vm.dialogController = $$v;
      },
      expression: "dialogController"
    }
  }, [_c('v-card', [_vm.tabModel === 1 ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('indicateChanges')) + " ")]) : _vm._e(), _c('v-divider', {
    staticClass: "mb-2 mx-4"
  }), _c('v-tabs-items', {
    model: {
      value: _vm.tabModel,
      callback: function ($$v) {
        _vm.tabModel = $$v;
      },
      expression: "tabModel"
    }
  }, [_c('v-tab-item', [_c('v-card-text', {
    staticClass: "pt-6"
  }, [!_vm.uploadErrorMessage ? _c('p', {
    staticClass: "body-1 mb-8 text-center px-3"
  }, [_vm.uploadStatus !== 100 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('UploadingDontLeave')) + " ")]) : _c('span', {
    staticClass: "success--text"
  }, [_vm._v(_vm._s(_vm.$t('FileUploaded')))])]) : _c('p', {
    staticClass: "body-1 mb-8 text-center px-1 red--text"
  }, [_vm._v(" " + _vm._s(_vm.uploadErrorMessage) + " ")]), _c('ProgressSingle', {
    staticClass: "pb-6",
    attrs: {
      "value": _vm.uploadStatus,
      "error": !!_vm.uploadErrorMessage
    }
  }), _vm.compressionInProgress ? _c('p', {
    staticClass: "body-1 mt-4 mb-4 text-center px-1"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-spinner fa-pulse ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('CompressionInProgress')) + " ")])], 1) : _vm._e()], 1)], 1), _c('v-tab-item', [_c('v-card-text', {
    staticClass: "px-4 py-0"
  }, [_vm.viewerPartFile && _vm.tabModel === 1 ? _c('Viewer3d', {
    ref: "viewer3d",
    attrs: {
      "part": _vm.part,
      "can-modify-modifications": true,
      "clipping": false,
      "initial-fullscreen": true,
      "fullscreen-control-enabled": false,
      "inner-height-percent": 85,
      "part-file": _vm.viewerPartFile,
      "toolbar": true
    },
    on: {
      "renderingReady": _vm.activateModificationsTool,
      "exitEditing": _vm.checkExitStatus
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "disabled": !_vm.canClose && _vm.tabModel === 0
    },
    on: {
      "click": _vm.activateModificationsTool
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("Close")) + " ")], 1), _c('v-spacer'), _vm.tabModel === 0 ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "disabled": !_vm.canStartModifications
    },
    on: {
      "click": _vm.startModifications
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Next")) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-right ")])], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }