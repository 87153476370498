var render = function render(){
  var _vm$partObject;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.partObject ? _c('v-skeleton-loader', {
    attrs: {
      "type": "card"
    }
  }) : _c('AttachmentsManager', {
    attrs: {
      "attachments": ((_vm$partObject = _vm.partObject) === null || _vm$partObject === void 0 ? void 0 : _vm$partObject.attachments) || [],
      "owner": _vm.$route.params.brandUUID,
      "upload-status-and-progress": _vm.uploadStatusAndProgress,
      "shared-with": "supplier"
    },
    on: {
      "addAttachment": _vm.addAttachment,
      "updateAttachment": _vm.updateAttachment,
      "downloadAttachment": _vm.downloadAttachment,
      "deleteAttachment": _vm.deleteAttachment,
      "refresh": _vm.refresh
    }
  }), _c('UploadManager', {
    ref: "UploadManagerRef",
    on: {
      "success": _vm.uploadSuccess,
      "error": _vm.uploadError,
      "progress": _vm.uploadProgress,
      "xhr": _vm.uploadProgress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }