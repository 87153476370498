var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "text": "",
            "fab": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('goToBrandPartQuotes');
            }
          }
        }, [_c('v-badge', {
          staticClass: "badgeBrandPartQuotes",
          attrs: {
            "bordered": "",
            "color": "orange",
            "overlap": "",
            "value": _vm.awaitingActionQuotes > 0
          },
          scopedSlots: _vm._u([{
            key: "badge",
            fn: function () {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.awaitingActionQuotes) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.color,
            "small": ""
          }
        }, [_vm._v(" fas fa-file-signature ")])], 1)], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('Quotes')) + " ")]), _c('span', [_vm._v(_vm._s(_vm.$t('(quotesInProgress)', {
    numberOfQuotes: _vm.unfinishedQuotes
  })))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }