var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "stickyContainer"
  }, [_c('v-card', {
    staticClass: "menuAction rounded-pill pa-0 d-flex flex-column"
  }, [_c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "text": "",
            "fab": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('open', 'ModalPartInformation');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "primary",
            "small": ""
          }
        }, [_vm._v(" fas fa-file-alt ")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('partInformation')))])]), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "text": "",
            "fab": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('open', 'attachmentsManager');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "primary",
            "small": ""
          }
        }, [_vm._v(" fas fa-paperclip fa-flip-vertical ")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('partInformation')))])]), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "text": "",
            "fab": "",
            "disabled": !_vm.$appConfig.brand.features.enableTags || _vm.part.brand_id !== _vm.$route.params.brandUUID
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('open', 'ModalPartTags');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "primary",
            "small": ""
          }
        }, [_vm._v(" fas fa-tags ")])], 1)], 1)];
      }
    }])
  }, [!_vm.$appConfig.brand.features.enableTags ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('TagsFeatureIsAvailableAsPremiumOption')) + " ")]) : _vm.part.brand_id !== _vm.$route.params.brandUUID ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('noAccess')) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t('tags')))])]), _c('BrandPartQuotes', {
    attrs: {
      "brand-part-quotes": _vm.brandPartQuotes,
      "color": _vm.partQuotesIconColor
    },
    on: {
      "goToBrandPartQuotes": _vm.goToBrandPartQuotes
    }
  }), _c('BrandPartOrders', {
    attrs: {
      "part": _vm.part,
      "brand-part-orders": _vm.brandPartOrders
    },
    on: {
      "goToBrandPartOrders": _vm.goToBrandPartOrders
    }
  }), _c('v-btn', {
    attrs: {
      "text": "",
      "fab": "",
      "color": "info",
      "loading": _vm.loadingFile,
      "disabled": _vm.part.brand_id !== _vm.$route.params.brandUUID
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('open', 'fileExtensionModal');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-fas fa-download ")])], 1), _c('PartSharingManager', {
    ref: "sharePart",
    attrs: {
      "part": _vm.part,
      "share-with": _vm.shareWith,
      "brand": _vm.$appConfig.brand,
      "current-sharing": _vm.currentSharing,
      "disabled": _vm.part.brand_id !== _vm.$route.params.brandUUID
    },
    on: {
      "add": _vm.addSharing,
      "delete": _vm.deleteSharing
    },
    scopedSlots: _vm._u([{
      key: "append-add",
      fn: function () {
        return [_c('SupportEmail', {
          ref: "supportSharing",
          attrs: {
            "hidden-pre-subject": `[SHARING-${_vm.part._id}]`,
            "default-subject": _vm.$t('SharingSupportSubject'),
            "display-information": false,
            "display-button": false
          }
        }), _c('v-btn', {
          staticClass: "text-none mt-2 ml-3",
          attrs: {
            "color": "info",
            "fab": "",
            "small": "",
            "title": "Support"
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.supportSharing.open();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-headset ")])], 1)];
      },
      proxy: true
    }])
  }), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "disabled": !_vm.$appConfig.brand.features.enableSerialNumber,
            "text": "",
            "fab": "",
            "color": "info",
            "to": {
              name: 'Traceability',
              query: {
                partName: _vm.part.name
              }
            }
          }
        }, [_vm.$appConfig.brand.features.enableSerialNumber ? _c('v-img', {
          attrs: {
            "src": require("../../assets/scan_color_info.png"),
            "max-height": "18px",
            "contain": ""
          }
        }) : _c('v-img', {
          attrs: {
            "src": require("../../assets/scan_color_disabled.png"),
            "max-height": "18px",
            "contain": ""
          }
        })], 1)], 1)];
      }
    }])
  }, [_vm.$appConfig.brand.features.enableSerialNumber ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('SerialNumbersGenerated')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('SerialNumberFeatureDisabled')) + " ")])]), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "text": "",
            "fab": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('open', 'SupportModal');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "primary",
            "small": ""
          }
        }, [_vm._v(" fas fa-headset ")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ContactSupport')))])]), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "disabled": !_vm.canBeDeleted || _vm.part.brand_id !== _vm.$route.params.brandUUID,
            "text": "",
            "fab": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('open', 'deletePartModal');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "info",
            "small": ""
          }
        }, [_vm._v(" fas fa-trash ")])], 1)], 1)];
      }
    }])
  }, [!_vm.canBeDeleted ? _c('span', [_vm._v(_vm._s(_vm.$t('cantDeletePart')))]) : _c('span', [_vm._v(_vm._s(_vm.$t('DeletePart')) + " ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }