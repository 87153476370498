var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-addToBasket"
  }, [!_vm.displayProposition ? _c('v-text-field', {
    staticClass: "headline text-center addToBasketTextField pr-12 mb-1",
    attrs: {
      "solo": "",
      "min": "1",
      "hide-details": "",
      "append-icon": "fas fa-plus",
      "prepend-inner-icon": "fas fa-minus",
      "append-outer-icon": "fas fa-cart-plus",
      "type": "number"
    },
    on: {
      "keydown": _vm.updateEstimateTotalPrice,
      "input": _vm.updateEstimateTotalPrice,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addToBasket.apply(null, arguments);
      },
      "click:append": function ($event) {
        _vm.numberToOrder = parseInt(_vm.numberToOrder) + 1;
        _vm.updateEstimateTotalPrice();
      },
      "click:prepend-inner": function ($event) {
        _vm.numberToOrder > 1 ? _vm.numberToOrder = parseInt(_vm.numberToOrder) - 1 : '';
        _vm.updateEstimateTotalPrice();
      },
      "click:append-outer": _vm.addToBasket
    },
    model: {
      value: _vm.numberToOrder,
      callback: function ($$v) {
        _vm.numberToOrder = $$v;
      },
      expression: "numberToOrder"
    }
  }) : _vm._e(), _vm.estimateTotalPrice > 0 ? _c('span', {
    staticClass: "caption px-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('estimateTotalPrice')) + " " + _vm._s(_vm.$n(_vm.estimateTotalPrice, 'currency', _vm.$userLocale)) + " ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }