var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-replacePartFile mr-2"
  }, [_c('UploadManager', {
    ref: "UploadManagerRef",
    on: {
      "progress": _vm.checkProgress,
      "error": _vm.listUploadError,
      "success": _vm.uploadSucceeded
    }
  }), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".stl,.3mf,.step,.stp,.obj,.iges,.igs"
    },
    on: {
      "change": _vm.partsUploadFilesChanges
    }
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "350px",
      "content-class": "module-replacePartFile"
    },
    model: {
      value: _vm.dialogController,
      callback: function ($$v) {
        _vm.dialogController = $$v;
      },
      expression: "dialogController"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pt-6"
  }, [!_vm.uploadErrorMessage ? _c('p', {
    staticClass: "body-1 mb-8 text-center px-3"
  }, [_vm.uploadStatus !== 100 ? _c('span', [_vm._v(_vm._s(_vm.$t('UploadingDontLeave')))]) : _c('span', {
    staticClass: "success--text"
  }, [_vm._v(_vm._s(_vm.$t('FileUploaded')))])]) : _c('p', {
    staticClass: "body-1 mb-8 text-center px-1 red--text"
  }, [_vm._v(" " + _vm._s(_vm.uploadErrorMessage) + " ")]), _c('ProgressSingle', {
    staticClass: "pb-6",
    attrs: {
      "value": _vm.uploadStatus,
      "error": !!_vm.uploadErrorMessage
    }
  }), _vm.compressionInProgress ? _c('p', {
    staticClass: "body-1 mt-4 mb-4 text-center px-1"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-spinner fa-pulse ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('CompressionInProgress')) + " ")])], 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "disabled": !_vm.canClose
    },
    on: {
      "click": _vm.cancelUploadFile
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("Close")) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }