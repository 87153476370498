<template>
  <v-tooltip right>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-btn
          text
          fab
          @click="$emit('goToBrandPartQuotes')"
        >
          <v-badge
            bordered
            color="orange"
            class="badgeBrandPartQuotes"
            overlap
            :value="awaitingActionQuotes > 0"
          >
            <template v-slot:badge>
              <span>
                {{ awaitingActionQuotes }}
              </span>
            </template>
            <v-icon :color="color" small>
              fas fa-file-signature
            </v-icon>
          </v-badge>
        </v-btn>
      </div>
    </template>
    <span>{{ $t('Quotes') }} </span>
    <span>{{ $t('(quotesInProgress)', {numberOfQuotes: unfinishedQuotes}) }}</span>
  </v-tooltip>
</template>

<script>
const i18nData = require('./brandPartQuotes.i18n');

export default {
  name: 'BrandPartQuotes',
  props: {
    brandPartQuotes: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: () => {
        return 'primary';
      } 
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      currentQuoteAwaitingAction: [],
      currentQuoteNotFinished: [],
    };
  },
  created() {
  },
  computed: {
    unfinishedQuotes() {
      return this.brandPartQuotes.reduce((previousValue, quote) => {
        if(quote.status !== 'DECLINED' && quote.status !== 'ACCEPTED' && quote.status !== 'CLOSED') {
          return previousValue + 1;
        }
        else {
          return previousValue;
        }
      }, 0);
    },
    awaitingActionQuotes() {
      return this.brandPartQuotes.reduce((previousValue, quote) => {
        if(quote.status === 'READY') {
          return previousValue + 1;
        }
        else {
          return previousValue;
        }
      }, 0);
    },
  },
  methods: {
  }
};
</script>

<style>
 .badgeBrandPartQuotes .v-badge__wrapper .v-badge__badge {
    inset: auto auto calc(100% - 6px) calc(100% - 13px) !important;
}
</style>
 