<template lang="html" src="./addToBasket.template.vue"></template>

<style lang="scss" src="./addToBasket.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./addToBasket.i18n');

export default {
  name: 'AddToBasket',
  props: {
    part: {
      type: Object,
      required: true,
    },
    origin: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      estimateTotalPrice: 0,  
      numberToOrder: 1,
      displayProposition: false,
    };
  },
  created() {
    this.updateEstimateTotalPrice();
  },
  methods: {
    addToBasket() {
      /**
       * Send event to add part to the basket with the quantity
       * Event received by component "BasketMenu"
       */
      this.$emit('addPartToBasket', this.part._id, Number(this.numberToOrder));
      this.$emit('addToBasketSuccess');
      this.displayProposition = true;
      this.numberToOrder = 1;
      this.updateEstimateTotalPrice();
    },
    pricesSplitter(prices, quantity) {
      const result = [];

      const pricesList = [];
      prices.forEach(price => {
        pricesList.push(price);
      });
      /**
   * Sort price with max quantity first
   */
      pricesList.sort(function(a, b) {
        return b.quantity - a.quantity;
      });

      /**
   * Get Unitary Price
   */
      let unitaryPrice = null;
      pricesList.forEach(price => {
        if (price.quantity === 1) {
          unitaryPrice = price.sellingPrice;
          return;
        }
      });

      if (!Number.isFinite(unitaryPrice)) {
        const error = new Error('Error during quote split calculation');
        error.code = 500;
        throw error;
      }

      const priceWithoutDiscount =
        pricesList[pricesList.length - 1].sellingPrice * quantity;
      let priceWithDiscount = 0;
      let priceObject = null;
      for (const price of pricesList) {
        if (price.quantity <= quantity) {
          priceObject = price;
          priceWithDiscount = price.sellingPrice * quantity;
          break;
        }
      }
      const discount = priceWithoutDiscount - priceWithDiscount;

      result.push({
        batchNumber: 1,
        quantityByBatch: quantity,
        quantity: quantity,
        unitaryPrice: pricesList[pricesList.length - 1].sellingPrice,
        totalUnitaryPrice: priceWithoutDiscount,
        price: priceObject.sellingPrice,
        purchasePrice: priceObject.purchasePrice,
        totalPrice: priceWithDiscount,
        totalPurchasePrice: priceObject.purchasePrice * quantity,
        batchesAdvantage: -discount,
      });
      this.estimateTotalPrice = result[0].totalPrice;
    },
    updateEstimateTotalPrice() {
      if (!this.numberToOrder) this.numberToOrder = 1;
      if(this.part.homologation?.prices.length > 0) {
        this.pricesSplitter(this.part.homologation.prices, this.numberToOrder);
      }
    }
  },
};
</script>
